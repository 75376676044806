import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import ShowLoading from "../../components/ShowLoading";
import ShowError from "../../components/ShowError";
import { getAdminStates } from "../helpers/adminapis";

function AdminStates(_props) {

    const admin = useSelector(state => state.user);
    const [adminStates, setAdminStates] = useState({
        totalUsers: 0,
        amountCreditedThisMonth: 0,
        commissionEarnedThisMonth: 0,
        amountDebitedThisMonth: 0
    });
    const { totalUsers, amountCreditedThisMonth, commissionEarnedThisMonth, amountDebitedThisMonth } = adminStates;
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    const setAdminStateData = () => {
        getAdminStates(admin._id, admin.token)
            .then(data => {
                if (data?.error) {
                    setError(data.error)
                } else if (data) {
                    setAdminStates(data)
                }
                setIsLoading(false);
            })
    }

    useEffect(() => {
        setAdminStateData();
    }, [])

    return (
        <div class="d-flex justify-content-center">

            <div class="col-md-10">
                <div class="row ">
                    <ShowLoading isLoading={isLoading} />
                    <ShowError error={error} />
                    <div class="col-xl-6 col-lg-6">
                        <div class="card-ud l-bg-purple shadow">
                            <div class="card-statistic-3 p-4">
                                <div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
                                <div class="mb-4">
                                    <h5 class="card-title mb-0">Total Commission This Month</h5>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                    <div class="col-8">
                                        <h2 class="d-flex align-items-center mb-0">
                                            {(commissionEarnedThisMonth ?? 0).toLocaleString('en-IN', {
                                                style: 'currency',
                                                currency: 'INR'
                                            })}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6">
                        <div class="card-ud l-bg-cherry shadow">
                            <div class="card-statistic-3 p-4">
                                <div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
                                <div class="mb-4">
                                    <h5 class="card-title mb-0">Total Users</h5>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                    <div class="col-8">
                                        <h2 class="d-flex align-items-center mb-0">
                                            {totalUsers ?? 0}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6">
                        <div class="card-ud l-bg-green-dark shadow">
                            <div class="card-statistic-3 p-4">
                                <div class="card-icon card-icon-large"><i class="fas fa-hand-holding-dollar"></i></div>
                                <div class="mb-4">
                                    <h5 class="card-title mb-0">Money Credited This Month</h5>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                    <div class="col-8">
                                        <h2 class="d-flex align-items-center mb-0">
                                            {(amountCreditedThisMonth ?? 0).toLocaleString('en-IN', {
                                                style: 'currency',
                                                currency: 'INR'
                                            })}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6">
                        <div class="card-ud l-bg-orange-dark shadow">
                            <div class="card-statistic-3 p-4">
                                <div class="card-icon card-icon-large"><i class="fas fa-money-bill-trend-up"></i></div>
                                <div class="mb-4">
                                    <h5 class="card-title mb-0">Total Debited This Month</h5>
                                </div>
                                <div class="row align-items-center mb-2 d-flex">
                                    <div class="col-8">
                                        <h2 class="d-flex align-items-center mb-0">
                                            {(amountDebitedThisMonth ?? 0).toLocaleString('en-IN', {
                                                style: 'currency',
                                                currency: 'INR'
                                            })}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AdminStates;