import React, { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ShowLoading from "../components/ShowLoading";
import ShowError from "../components/ShowError";
import UserNav from './components/UserNav';
import UserProfile from "./components/UserProfile";
import EditProfile from "./components/EditProfile";
import CreateLifafa from "./components/CreateLifafa";
import ListLifafas from "./components/ListLifafa";
import AddBalance from "./components/AddBalance";
import TransactionLogs from "./components/TransactionLogs";
import APIDoc from "./components/APIDoc";
import Withdraw from "./components/Withdrawal";
import UserSettings from "./components/UserSettings";

function UserDashboard(_props) {

	const [activeTab, setActiveTab] = useState("profile");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);

	return (
		<div>
			<Header />
			<div className="container my-5" style={{minHeight: "70vh"}}>
				<ShowLoading isLoading={isLoading} />
				<ShowError error={error} />
				<div className="row">
					<div className="col-md-3">
						<UserNav activeTab={activeTab} setActiveTab={setActiveTab} />
					</div>
					<div className="col">
						{activeTab === "profile" && <UserProfile />}
						{activeTab === "edit" && <EditProfile />}
						{activeTab === "createLifafa" && <CreateLifafa />}
						{activeTab === "listLifafa" && <ListLifafas />}
						{activeTab === "addBalance" && <AddBalance />}
						{activeTab === "transactionLogs" && <TransactionLogs />}
						{activeTab === "apiDocs" && <APIDoc />}
						{activeTab === "withdraw" && <Withdraw />}
						{/* {activeTab === "settings" && <UserSettings />} */}
					</div>
				</div>
			</div>

			<Footer small={false} />
		</div>
	);
}

export default UserDashboard;