import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ShowLoading from "../components/ShowLoading";
import ShowError from "../components/ShowError";
import ShowSuccess from "../components/ShowSuccess";
import { claimLifafa, getLifafaById } from "./helper/userapi";
import { useParams } from "react-router-dom";
import validator from 'validator';

function LifafaClaim(_props) {

    const { lifafaId } = useParams();
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [paymentNumber, setPaymentNumber] = useState("");
    const [lifafaData, setLifafaData] = useState();

    const handleChange = (event) => {
        event.preventDefault();
        setPaymentNumber(event.target.value);
        setError(false);
        setSuccess(false);
    }

    const handleClick = (event) => {
        event.preventDefault();
        if (!validator.isMobilePhone(paymentNumber) || paymentNumber.length !== 10) return setError("Please enter a valid number!")
        setError(false);
        setSuccess(false);
        setIsLoading(true);
        claimLifafa(lifafaId, { paymentNumber })
            .then(data => {
                if (data?.error) {
                    setError(data.error)
                } else {
                    setSuccess(data.message)
                    setPaymentNumber("")
                }
                setIsLoading(false);
            })
            .catch(err => console.log(err) && setIsLoading(false))
    }

    const setLifafaDetails = (lifafaId) => {
        setIsLoading(true);
        getLifafaById(lifafaId)
            .then(data => {
                if (data?.error) {
                    setError(data.error)
                } else {
                    setLifafaData(data)
                }
                setIsLoading(false)
            })
    }

    useEffect(() => {
        setLifafaDetails(lifafaId);
    }, [])

    return (
        <div>
            <Header />
            <div className="my-3 mx-3 row justify-content-center">
                <div className="card px-5 py-5 col-xl-6 col-lg-6 mb-5">
                    <ShowError error={error} />
                    <ShowLoading isLoading={isLoading} />
                    <ShowSuccess success={success} />
                    <div className="m-auto">
                        <lord-icon
                            src="https://cdn.lordicon.com/tyvtvbcy.json"
                            trigger="loop"
                            delay="1000"
                            colors="primary:#892cdc"
                            style={{ width: "150px", height: "150px" }}>
                        </lord-icon>
                    </div>
                    <div className="d-inline fw-bold mt-4">
                        <div className="col-xl-12 col-lg-12 pb-2">
                            <div><i class="fas fa-newspaper me-2"></i> Description: <span className="fw-light ms-2">{lifafaData?.description}</span></div>
                        </div>
                        <div className="col-xl-12 col-lg-12 pb-2">
                            <div><i class="fas fa-user-tie me-2"></i> Lifafa offered by: <span className="fw-light ms-2">{lifafaData?.owner?.name}</span></div>
                        </div>
                    </div>
                    <hr />
                    <div className="form-data">
                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">Mobile number</span>
                            <input onChange={handleChange} value={paymentNumber} type="tel" className="form-control mt-1" placeholder="Enter your number here ..." pattern="[1-9]{1}[0-9]{9}" />
                        </div>
                        <div className="mb-3">
                            <button onClick={handleClick} className="btn btn-dark w-100 mt-3 fw-bold">Claim lifafa</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer small={false} />
        </div>
    );
}

export default LifafaClaim;