import React, { useEffect, useState } from "react";
import ShowLoading from "../../components/ShowLoading";
import ShowError from "../../components/ShowError";
import { useDispatch, useSelector } from 'react-redux';
import { createLifafa, getUserById } from "../helper/userapi";
import { setAdditionalUserData } from "../../state/reducers/userReducers";
import { isEmpty } from "lodash";

function CreateLifafa(_props) {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState({
        description: "",
        balance: 10,
        remainingLifafas: 5
    });
    const [lifafaId, setLifafaId] = useState();

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const handleChange = name => (event) => {
        event.preventDefault();
        setData({ ...data, [name]: event.target.value });
        setError(false);
    }

    const handleClick = (event) => {
        event.preventDefault();
        setSuccess(null);
        setError(null);

        if (user.balance < (data.remainingLifafas * data.balance)) return setError("Insufficient balance!");
        if (isEmpty(data.description)) return setError("Please add description!");
        if (data.balance < 1 || data.remainingLifafas < 1) return setError("Lifafa amount and number of lifafas cannot be zero!");

        setIsLoading(true);
        createLifafa(data, user._id, user.token)
            .then(res => {
                if (!res?.error) {
                    setData({
                        description: "",
                        balance: 0,
                        remainingLifafas: 0
                    })
                    setLifafaId(res._id);
                    getUserAdditionalDetails();
                    setSuccess(true);
                } else {
                    setError(res.error);
                    setIsLoading(false);
                }
            })
            .catch(err => console.log(err) && setIsLoading(false) && setError(err));
    }

    const getUserAdditionalDetails = () => {
        getUserById(user._id, user.token)
            .then((res) => {
                if (res?.error) {
                    setIsLoading(false)
                    return setError(res.error)
                }

                if (res) {
                    dispatch(setAdditionalUserData({
                        balance: res.balance,
                        commission: res.commission,
                        withdrawalsToday: res.withdrawalsToday,
                        moneyWithdrawnToday: res.moneyWithdrawnToday,
                        totalWithdrawn: res.totalWithdrawn,
                        totalLifafas: res.totalLifafas,
                        activeLifafas: res.activeLifafas
                    }))
                }

                setIsLoading(false)
            })
            .catch((error) => console.log(error) && setError(error) && setIsLoading(false))
    }

    const getNumberInCurrency = (number, isBalanceChecker) => {
        if (number < 0 && isBalanceChecker) return "Insufficient balance!"
        return (number ?? 0).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        })
    }

    const ShowSuccess = () => {
        return (
            <div className="alert alert-success text-center fw-bold" role="alert" style={{ display: success ? "" : "none" }}>
                Lifafa created successfully!   Link to claim lifafas:
                <span className="theme-text ms-2">
                    {`${process.env.REACT_APP_FRONTEND_URL}/lifafa/${lifafaId}`}
                </span>
            </div>
        );
    }

    useEffect(() => {
        getUserAdditionalDetails();
    }, []);

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-md-10 mb-5">
                <ShowError error={error} />
                <ShowLoading isLoading={isLoading} />
                {ShowSuccess()}
                <div className="card py-5 px-5 shadow-lg">
                    <div className="row d-flex justify-content-between align-items-center pb-5">
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-success m-2 p-2 rounded fw-bold text-center">Current balance:  {getNumberInCurrency(user.balance)}</div>
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-danger theme-bg m-2 p-2 rounded fw-bold text-center">Amount to be deducted:  {getNumberInCurrency(data.balance * data.remainingLifafas)}</div>
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-warning m-2 p-2 rounded fw-bold text-center">Balance after creating lifafa:  {getNumberInCurrency(user.balance - (data.balance * data.remainingLifafas), true)}</div>
                    </div>
                    <div className="form-data">
                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">
                                Description
                            </span>
                            <input
                                type="text"
                                className="form-control mt-1"
                                placeholder="Please write description here ..."
                                value={data.description}
                                onChange={handleChange("description")}
                            />
                        </div>
                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">
                                Lifafa Amount
                            </span>
                            <input
                                type="tel"
                                className="form-control mt-1"
                                value={data.balance}
                                onChange={handleChange("balance")}
                            />
                        </div>
                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">
                                Total Number of Lifafas
                            </span>
                            <input
                                type="tel"
                                className="form-control mt-1"
                                value={data.remainingLifafas}
                                onChange={handleChange("remainingLifafas")}
                            />
                        </div>
                        <div className="mb-3">
                            <button
                                className="btn btn-dark w-100 mt-3 fw-bold"
                                onClick={handleClick}
                            >
                                Create Lifafa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateLifafa;