import API from "../backend";
import jwt_decode from "jwt-decode";

export const registerUser = async (data) => {
	return fetch(`${API}/register`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const loginUser = async (data) => {
	return fetch(`${API}/login`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const authenticate = (data, next) => {
	if (typeof window !== "undefined") {
		localStorage.setItem("authtoken", JSON.stringify(data));
		next();
	}
}

export const logout = async (next) => {
	if (typeof window !== "undefined") {
		localStorage.removeItem("authtoken")
		next();

		return fetch(`${API}/logout`, {
			method: "GET"
		})
			.then()
			.catch(err => console.log(err));
	}
}

export const isAuthenticated = () => {
	if (typeof window == "undefined")
		return false;
	if (localStorage.getItem("authtoken")) {
		const jsonData = JSON.parse(localStorage.getItem("authtoken"))
		const user = jwt_decode(jsonData.authtoken)
		const data = {
			_id: user._id,
			name: user.name,
			email: user.email,
			role: user.role,
			mobileNumber: user.mobileNumber,
			avatar: user.avatar,
			token: jsonData.authtoken,
			status: user.status
		}
		return data
	}
	else
		return false;
}

export const updateUser = async (data, userId, token) => {
	return fetch(`${API}/user/${userId}/update`, {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const getTitleDetails = async () => {
	return fetch(`${API}/settings/title`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json"
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}