import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

function Home(_props) {

	const user = useSelector((state) => state.user);

	return (
		<div>
			<Header />
			<div className="container">
				<div class="pb-5 px-5 mb-4 bg-body-tertiary rounded-3">
					<div class="container-fluid py-5">
						<h1 class="display-5 fw-bold mb-3">{process.env.REACT_APP_NAME}</h1>
						<p class="col-md-8 fs-4 mb-4">Using a series of utilities, you can create this jumbotron, just like the one in previous versions of Bootstrap. Check out the examples below for how you can remix and restyle it to your liking.</p>
						<NavLink to={`/register`} ><button class="btn btn-dark btn-lg" type="button">Get started</button></NavLink>
					</div>
				</div>

				<div class="row align-items-md-stretch mb-5">
					<div class="col-md-6 mb-3">
						<div class="h-100 p-5 text-bg-dark rounded-3">
							<h2 className="mb-3">Manage all your needs</h2>
							<p className="mb-4">Or, keep it light and add a border for some added definition to the boundaries of your content. Be sure to look under the hood at the source HTML here as we've adjusted the alignment and sizing of both column's content for equal-height.</p>
							<NavLink to={`/user/${user?._id}/profile`} ><button class="btn btn-light fw-bold" type="button">User dashboard</button></NavLink>
						</div>
					</div>
					<div class="col-md-6 text-white mb-3">
						<div class="h-100 p-5 bg-body-tertiary theme-bg border rounded-3">
							<h2 className="mb-3">About Us</h2>
							<p className="mb-4">Or, keep it light and add a border for some added definition to the boundaries of your content. Be sure to look under the hood at the source HTML here as we've adjusted the alignment and sizing of both column's content for equal-height.</p>
							<NavLink to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} ><button class="btn btn-light fw-bold" type="button">Contact Us</button></NavLink>
						</div>
					</div>
				</div>
			</div>
			<Footer small={false} />
		</div>
	);
}

export default Home;