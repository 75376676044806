import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
import Home from "./core/Home";
import Login from "./core/Login";
import Register from "./core/Register";
import PrivateRoute from "./auth/PrivateRoute";
import UserDashboard from "./core/UserDashboard";
import LifafaClaim from "./core/LifafaClaim";
import AdminLogin from "./admin/AdminLogin";
import AdminRoute from "./auth/AdminRoute";
import AdminPanel from "./admin/AdminPanel";
import ManageUser from "./admin/ManageUser";

function RoutesManager(_props) {

	const user = useSelector((state) => state.user);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/register" element={<Register />} />
				<Route path="/login" element={<Login />} />
				<Route path="/admin/login" element={<AdminLogin />} />
				<Route path="/user/:userId/profile" element={<PrivateRoute user={user} ><UserDashboard /></PrivateRoute>} />
				<Route path="/lifafa/:lifafaId" element={<LifafaClaim />} />
				<Route path="/admin/:adminId/panel" element={<AdminRoute user={user} ><AdminPanel /></AdminRoute>} />
				<Route path="/admin/:adminId/users/:userId/manage" element={<AdminRoute user={user} ><ManageUser /></AdminRoute>} />
			</Routes>
		</BrowserRouter>
	);
}

export default RoutesManager;
