import React, { useEffect, useState } from "react";
import ShowLoading from "../../components/ShowLoading";
import ShowError from "../../components/ShowError";
import ShowSuccess from "../../components/ShowSuccess";
import { useDispatch, useSelector } from 'react-redux';
import { addUserBalance, getUserById } from "../helper/userapi";
import { setAdditionalUserData } from "../../state/reducers/userReducers";

function AddBalance(_props) {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [balance, setBalance] = useState(50);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const handleChange = name => (event) => {
        event.preventDefault();
        setBalance(event.target.value);
        setError(false);
        setSuccess(false);
    }

    const handleClick = (event) => {
        event.preventDefault();
        if (Number(balance) <= 0) return setError("Amount should be greater than zero!")
        setIsLoading(true);
        //TODO: should redirect to payment gateway and get transactionId after the payment
        addUserBalance(user._id, { amount: balance, transactionId: "fakeForNow" }, user.token)
            .then(data => {
                if (data?.error) {
                    setError(data.error)
                } else {
                    setSuccess(data.message)
                }
                getUserAdditionalDetails();
                setBalance(0);
                setIsLoading(false);
            })
            .catch(err => console.log(err) && setIsLoading(false))
    }

    const getAmountToPayWithCommission = (amount, commission) => {
        return Number(amount) + ((Number(commission) * Number(amount)) / 100)
    }

    const getNumberInCurrency = (number) => {
        return (number ?? 0).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        })
    }

    const getUserAdditionalDetails = () => {
        getUserById(user._id, user.token)
            .then((res) => {
                if (res?.error) {
                    setIsLoading(false)
                    return setError(res.error)
                }

                if (res) {
                    dispatch(setAdditionalUserData({
                        balance: res.balance,
                        commission: res.commission,
                        withdrawalsToday: res.withdrawalsToday,
                        moneyWithdrawnToday: res.moneyWithdrawnToday,
                        totalWithdrawn: res.totalWithdrawn,
                        totalLifafas: res.totalLifafas,
                        activeLifafas: res.activeLifafas
                    }))
                }

                setIsLoading(false)
            })
            .catch((error) => console.log(error) && setError(error) && setIsLoading(false))
    }

    useEffect(() => {
        getUserAdditionalDetails();
    }, []);

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-md-10 mb-5">
                <ShowError error={error} />
                <ShowLoading isLoading={isLoading} />
                <ShowSuccess success={success} />
                <div className="card py-5 px-5 shadow-lg">
                    <div className="row d-flex justify-content-between align-items-center pb-5">
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-success m-2 p-2 rounded fw-bold text-center">Current balance:  {getNumberInCurrency(user.balance)}</div>
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-danger theme-bg m-2 p-2 rounded fw-bold text-center">Amount to be paid:  {getNumberInCurrency(getAmountToPayWithCommission(balance, user.commission))}</div>
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-warning m-2 p-2 rounded fw-bold text-center">Updated balance:  {getNumberInCurrency(Number(user.balance) + Number(balance))}</div>
                    </div>
                    <div className="form-data">
                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">
                                Amount
                            </span>
                            <input
                                type="tel"
                                className="form-control mt-1"
                                value={balance}
                                onChange={handleChange("balance")}
                            />
                        </div>
                        <div className="mb-3">
                            <button
                                className="btn btn-dark w-100 mt-3 fw-bold"
                                onClick={handleClick}
                            >
                                Add Balance
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBalance;