import React from 'react';

function AdminNav(props) {

	return (<>
		<ul className="list-group mb-3 shadow">
			<li className={props.activeTab === "states" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("states")}><i class="fas fa-circle-info me-2"></i> Statistics</li>
			<li className={props.activeTab === "users" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("users")}><i class="fas fa-users-gear me-2"></i> Manage Users</li>
			<li className={props.activeTab === "edit" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("edit")}><i class="fas fa-user-pen me-2"></i> Edit profile</li>
			<li className={props.activeTab === "settings" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("settings")}><i class="fas fa-gear me-2"></i> Settings</li>
		</ul>
	</>);
}

export default AdminNav;