import API from "../../backend";

export const getTotalUserPages = async (adminId, token) => {
    return fetch(`${API}/admin/${adminId}/users/totalPages`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}

export const getUsersByPage = async (adminId, { page, pageSize }, token) => {
    return fetch(`${API}/admin/${adminId}/users-pg?pageSize=${pageSize}&pageNumber=${page}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}

export const searchUsers = async (adminId, search, token) => {
    return fetch(`${API}/admin/${adminId}/users/search?email=${search}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}

export const getUserDetailsById = async (adminId, userId, token) => {
    return fetch(`${API}/admin/${adminId}/users/${userId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}

export const updateUserDetailsById = async (adminId, userId, data, token) => {
    return fetch(`${API}/admin/${adminId}/users/${userId}/update`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}

export const getAdminStates = async (adminId, token) => {
    return fetch(`${API}/admin/${adminId}/states`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}

export const getSettings = async (adminId, token) => {
    return fetch(`${API}/admin/${adminId}/settings`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}

export const saveSettings = async (adminId, data, token) => {
    return fetch(`${API}/admin/${adminId}/settings`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .catch(err => console.log(err))
}