import React, { useEffect, useState } from 'react';
import { getSettings, saveSettings } from "../helpers/adminapis";
import { useSelector } from "react-redux";
import ShowLoading from "../../components/ShowLoading";
import ShowError from "../../components/ShowError";
import ShowSuccess from "../../components/ShowSuccess";

function AdminSettings(_props) {

    const admin = useSelector(state => state.user);
    const [settings, setSettings] = useState({
        title: []
    });
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    const { title } = settings;

    const setSettingsData = () => {
        getSettings(admin._id, admin.token)
            .then(data => {
                if (data?.error) {
                    setError(data.error)
                } else if (data) {
                    setSettings({
                        title: data.title.join(', ')
                    })
                }
                setIsLoading(false)
            })
            .catch(err => console.log(err) && setError(err.message) && setIsLoading(false))
    }

    const handleChange = name => (event) => {
        event.preventDefault();
        setSettings({ ...settings, [name]: event.target.value });
        setError(false);
    }

    const handleClick = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const data = {
            title: title.split(",").map(x => x.trim())
        }
        saveSettings(admin._id, data, admin.token)
            .then(res => {
                if (!res?.error) {
                    setIsLoading(false);
                    setSuccess("Settings updated successfully!");
                } else {
                    setError(res.error);
                    setIsLoading(false);
                }
            })
            .catch(err => console.log(err) && setIsLoading(false));
    }

    useEffect(() => {
        setSettingsData();
    }, [])

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-md-10 mb-5">
                <ShowError error={error} />
                <ShowLoading isLoading={isLoading} />
                <ShowSuccess success={success} />
                <div className="card py-5 px-5 shadow-lg">
                    <div className="form-data">

                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">
                                Title Texts
                            </span>
                            <input
                                type="text"
                                className="form-control mt-1"
                                value={title}
                                onChange={handleChange("title")}
                            />
                        </div>

                        <div className="mb-3">
                            <button
                                className="btn btn-dark w-100 mt-3 fw-bold"
                                onClick={handleClick}
                            >
                                Update Settings
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSettings;