import React, { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import AdminNav from './components/AdminNav';
import UsersList from "./components/UsersList";
import EditProfile from "../core/components/EditProfile";
import AdminStates from "./components/AdminStates";
import AdminSettings from "./components/AdminSettings";

function AdminPanel(_props) {

	const [activeTab, setActiveTab] = useState("states");

	return (
		<div>
			<Header />
			<div className="container my-5" style={{minHeight: "70vh"}}>
				<div className="row">
					<div className="col-md-3">
						<AdminNav activeTab={activeTab} setActiveTab={setActiveTab} />
					</div>
					<div className="col">
						{activeTab === "states" && <AdminStates />}
						{activeTab === "users" && <UsersList />}
                        {activeTab === "edit" && <EditProfile />}
						{activeTab === "settings" && <AdminSettings />}
					</div>
				</div>
			</div>

			<Footer small={false} />
		</div>
	);
}

export default AdminPanel;