import React, { useEffect, useState } from "react";
import ShowLoading from "../../components/ShowLoading";
import ShowError from "../../components/ShowError";
import ShowSuccess from "../../components/ShowSuccess";
import { useDispatch, useSelector } from 'react-redux';
import { getUserById, makeWithdraw } from "../helper/userapi";
import { setAdditionalUserData } from "../../state/reducers/userReducers";
import validator from 'validator';

function Withdraw(_props) {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState({
        amount: 10,
        paymentNumber: "",
        message: ""
    });

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const { amount, paymentNumber, message } = data;

    const handleChange = name => (event) => {
        event.preventDefault();
        setData({ ...data, [name]: event.target.value });
        setError(false);
        setSuccess(false);
    }

    const handleClick = (event) => {
        event.preventDefault();
        if (Number(amount) <= 0) return setError("Amount should be greater than zero!")
        if (!validator.isMobilePhone(paymentNumber) || paymentNumber.length !== 10) return setError("Please enter a valid number!")
        if (amount > user.balance) return setError("Insufficient balance! Please add balance first")
        setIsLoading(true);
        //TODO: should redirect to payment gateway and get transactionId after the payment
        makeWithdraw(user._id, data, user.token)
            .then(data => {
                if (data?.error) {
                    setError(data.error)
                } else {
                    setSuccess(data.message)
                    setData({
                        amount: 10,
                        paymentNumber: "",
                        message: ""
                    });
                }
                getUserAdditionalDetails();
                setIsLoading(false);
            })
            .catch(err => console.log(err) && setIsLoading(false))
    }

    const getNumberInCurrency = (number) => {
        return (number ?? 0).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        })
    }

    const getUserAdditionalDetails = () => {
        getUserById(user._id, user.token)
            .then((res) => {
                if (res?.error) {
                    setIsLoading(false)
                    return setError(res.error)
                }

                if (res) {
                    dispatch(setAdditionalUserData({
                        balance: res.balance,
                        commission: res.commission,
                        withdrawalsToday: res.withdrawalsToday,
                        moneyWithdrawnToday: res.moneyWithdrawnToday,
                        totalWithdrawn: res.totalWithdrawn,
                        totalLifafas: res.totalLifafas,
                        activeLifafas: res.activeLifafas
                    }))
                }

                setIsLoading(false)
            })
            .catch((error) => console.log(error) && setError(error) && setIsLoading(false))
    }

    useEffect(() => {
        getUserAdditionalDetails();
    }, []);

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-md-10 mb-5">
                <ShowError error={error} />
                <ShowLoading isLoading={isLoading} />
                <ShowSuccess success={success} />
                <div className="card py-5 px-5 shadow-lg">
                    <div className="row d-flex justify-content-between align-items-center pb-5">
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-success m-2 p-2 rounded fw-bold text-center">Current amount:  {getNumberInCurrency(user.balance)}</div>
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-danger theme-bg m-2 p-2 rounded fw-bold text-center">Amount to be deducted:  {getNumberInCurrency(amount)}</div>
                        <div class="col-xl-3 col-lg-3 badge-lg text-bg-warning m-2 p-2 rounded fw-bold text-center">Updated amount:  {getNumberInCurrency(Number(user.balance) - Number(amount))}</div>
                    </div>
                    <div className="form-data">
                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">
                                Message
                            </span>
                            <input
                                type="text"
                                className="form-control mt-1"
                                placeholder="Enter your message"
                                value={message}
                                onChange={handleChange("message")}
                            />
                        </div>
                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">
                                Payment number
                            </span>
                            <input
                                type="tel"
                                className="form-control mt-1"
                                placeholder="Enter payment number"
                                value={paymentNumber}
                                onChange={handleChange("paymentNumber")}
                            />
                        </div>
                        <div className="forms-inputs mb-4">
                            <span className="dark-text fw-bold">
                                Amount
                            </span>
                            <input
                                type="tel"
                                className="form-control mt-1"
                                value={amount}
                                onChange={handleChange("amount")}
                            />
                        </div>
                        <div className="mb-3">
                            <button
                                className="btn btn-dark w-100 mt-3 fw-bold"
                                onClick={handleClick}
                            >
                                Process withdrawal
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Withdraw;