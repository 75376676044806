import React from 'react';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function UserCard(props) {

    const admin = useSelector((state) => state.user);

    return (
        <div>
            <div className={props?.user?.status === "active" ? "card shadow mb-2 text-bg-success" : (props?.user?.status === "pause" || props?.user?.status === "pausedByUser" ? "card shadow mb-2 text-bg-warning" : "card shadow mb-2 text-bg-danger")}>
                <div className="px-3 pt-3 pb-2">
                    <div className="row fw-bold row justify-content-between">

                        <div className="d-flex text-white justify-content-between">
                            <div className="col-xl-9 col-lg-9 pb-2">
                                <div><i class="fas fa-newspaper me-2"></i> Name: <span className="fw-light ms-2">{props?.user?.name}</span></div>
                                <div><i class="fas fa-envelope me-2"></i> Email: <span className="fw-light ms-2">{props?.user?.email}</span></div>
                                <div><i class="fa-brands fa-creative-commons-sampling me-2"></i> Status: <span className="fw-light ms-2">{props?.user?.status}</span></div>
                                <div><i class="fas fa-coins me-2"></i> Balance: <span className="fw-light ms-2">{props?.user?.balance}</span></div>
                                <div><i class="fas fa-handshake me-2"></i> Commission: <span className="fw-light ms-2">{props?.user?.commission}</span></div>
                                <div><i class="fas fa-money-bill-trend-up me-2"></i> Money withdrawan today: <span className="fw-light ms-2">{props?.user?.moneyWithdrawnToday}</span></div>
                            </div>
                            <div className="col-xl-2 col-lg-2">
                                <NavLink to={`/admin/${admin?._id}/users/${props?.user?._id}/manage`} ><button className="btn btn-lg btn-light rounded w-100 fw-bold">Manage</button></NavLink>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserCard;