import React from 'react';

function TransactionCard(props) {

    const TRANSACTIO_TYPES = {
        balanceAdded: "You have added balance to your account",
        lifafaCreated: "You have created a lifafa",
        lifafaClaimed: "A user claimed a lifafa",
        withdrawalByAPI: "You have made withdrawal using our API"
    }

    return (
        <div>
            <div className={props?.transaction?.type === "balanceAdded" ? "card shadow mb-2 text-bg-success" : "card shadow mb-2 text-bg-danger"}>
                <div className="px-3 pt-3 pb-2">
                    <div className="row fw-bold row justify-content-between">

                        <div className="d-inline text-white">
                            <div className="col-xl-12 col-lg-12 pb-2">
                                <div><i class="fas fa-newspaper me-2"></i> Description: <span className="fw-light ms-2">{TRANSACTIO_TYPES[props?.transaction?.type]}</span></div>
                                <div><i class="fas fa-coins me-2"></i> Amount: <span className="fw-light ms-2">{props?.transaction?.amount}</span></div>
                                {props?.transaction?.transactionId && (<div><i class="fas fa-money-check me-2"></i> Transaction ID: <span className="fw-light ms-2">{props?.transaction?.transactionId}</span></div>)}
                                {props?.transaction?.paymentNumber && (<div><i class="fas fa-address-card me-2"></i> Payment Address: <span className="fw-light ms-2">{props?.transaction?.paymentNumber}</span></div>)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransactionCard;