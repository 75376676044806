import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Pagination } from '@mui/material';
import ShowLoading from "../../components/ShowLoading";
import ShowError from "../../components/ShowError";
import { ThemeProvider } from '@mui/material';
import muiTheme from "../../components/muiTheme";
import { getTotalTransactionPages, getTransactionsByPage } from "../helper/userapi";
import TransactionCard from "./TransactionCard";

function TransactionLogs(_props) {

    const user = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [transactions, setTransactions] = useState([]);

    const setTotalTransactionPages = () => {
        getTotalTransactionPages(user._id, user.token)
            .then(data => {
                if (data?.error) {
                    setError(data.error);
                } else {
                    setTotalPages(data.totalPages);
                }
                setIsLoading(false)
            })
            .catch(err => console.log(err) && isLoading(false))
    }

    const setTransactionsData = (page) => {
        getTransactionsByPage(user._id, { page, pageSize }, user.token)
            .then(data => {
                if (data?.error) {
                    setError(data.error);
                } else if (data) {
                    setTransactions(data);
                }
                setIsLoading(false)
            })
            .catch(err => console.log(err) && isLoading(false))
    }

    useEffect(() => {
        setTotalTransactionPages();
        setTransactionsData(pageNumber);
    }, []);

    const handlePageChange = (event, page) => {
        event.preventDefault();
        setIsLoading(true);
        setTransactionsData(page);
        setPageNumber(page);
    }

    return (
        <div>
            <ShowError error={error} />
            <ShowLoading isLoading={isLoading} />
            <>
                {transactions && transactions?.map((transaction) => <TransactionCard key={transaction._id} transaction={transaction} />)}
                {transactions?.length < 1 && <>
                    <div className="card fw-bold text-center p-5 shadow-lg">
                        <div className=" m-auto mb-5">
                            <lord-icon
                                src="https://cdn.lordicon.com/ncxoarcp.json"
                                trigger="loop"
                                delay="1000"
                                colors="primary:#892cdc"
                                style={{ width: "250px", height: "250px" }}>
                            </lord-icon>
                        </div>
                        <h3>There are no transaction logs yet!</h3>
                        <h3 className="mt-5">Click on 'Add Balance' to get started!</h3>
                    </div>
                </>}
                {totalPages > 1 && <ThemeProvider theme={muiTheme}><div className="mt-4"><Pagination count={totalPages} color="purple" size="large" onChange={handlePageChange} page={pageNumber} /></div></ThemeProvider>}
            </>
        </div>
    );
}

export default TransactionLogs;