import React, { useState } from 'react';

function LifafaCard(props) {

    const [isCopied, setIsCopied] = useState(false);

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL}/lifafa/${props?.lifafa?._id}`);
        setIsCopied(true);
    }

    return (
        <div>
            <div className={props?.lifafa?.remainingLifafas > 0 ? "card shadow mb-2" : "card shadow mb-2 disabled-effect"}>
                <div className="px-3 pt-3 pb-2">
                    <div className="row fw-bold row justify-content-between">

                        <div className="d-inline">
                            <div className="col-xl-12 col-lg-12 pb-2">
                                <div><i class="fas fa-newspaper me-2"></i> Description: <span className="fw-light ms-2">{props?.lifafa?.description}</span></div>
                            </div>
                            <div className="col-xl-12 col-lg-12 row justify-content-center text-center">
                                <div className="col-xl-4 col-lg-4 align-items-center justify-content-center my-2">
                                    <div className={`badge-lg text-bg-success p-2 text-center rounded ${props?.lifafa?.remainingLifafas > 0 ? 'disabled' : ''}`}>Amount per lifafa: {props?.lifafa?.balance}</div>
                                </div>
                                <div className="col-xl-4 col-lg-4 align-items-center justify-content-center my-2">
                                    <div className={`badge-lg text-bg-warning p-2 text-center rounded ${props?.lifafa?.remainingLifafas > 0 ? 'disabled' : ''}`}>Remaining lifafas: {props?.lifafa?.remainingLifafas}</div>
                                </div>
                                <div className="col-xl-4 col-lg-4 d-flex align-items-stretch justify-content-center my-2 hover-effect">
                                    <div className={`badge-lg text-bg-warning theme-bg text-white p-2 w-100 d-flex justify-content-center align-items-center rounded ${props?.lifafa?.remainingLifafas > 0 ? 'disabled' : ''}`} onClick={copyLinkToClipboard}><i class="fa-solid fa-copy me-2"></i> Copy link</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default LifafaCard;