import React, { useEffect, useState } from 'react';
import { getUserSecrets } from "../helper/userapi";
import { useSelector } from "react-redux";

function APIDoc(_props) {

    const [userData, setUserData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const user = useSelector((state) => state.user);

    const getUserSecretDetails = () => {
        getUserSecrets(user._id, user.token)
            .then((res) => {
                if (res?.error) {
                    setIsLoading(false)
                    return setError(res.error)
                }
                setUserData(res);
                setIsLoading(false);
            })
            .catch((error) => console.log(error) && setError(error) && setIsLoading(false))
    }

    useEffect(() => {
        getUserSecretDetails();
    }, []);

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_FRONTEND_URL}/api`);
    }

    return (
        <div class="col">
            <div>
                <div>
                    <div class="d-flex justify-content-between">
                        <h1>{process.env.REACT_APP_NAME} API</h1>
                        <span>
                            <small class="badge bg-secondary">Version: 1.0.0</small>
                            <small class="ms-2 badge bg-success">OpenApi: 3.0.3</small>
                        </span>
                    </div>
                    <p>{process.env.REACT_APP_NAME} Withdrawal API</p>
                    <div class="mt-2">
                        <div>
                            <h6>Servers</h6>
                            <ul class="list-group">
                                <li
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                >
                                    <span>
                                        <span className="fw-bold me-3">Production server:</span> {process.env.REACT_APP_BACKEND_URL}
                                    </span>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-primary theme-bg"
                                        onClick={copyLinkToClipboard}
                                    >
                                        <svg
                                            fill="white"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18px"
                                            height="18px"
                                            class=""
                                        >
                                            <path
                                                d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
                                            ></path>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mb-4 mt-4">
                        <h1>APIs</h1>
                        <div class="accordion" id="accordion">
                            <div class="accordion-item">
                                <h2
                                    class="accordion-header"
                                    id="1b766c29-49c7-4270-8f7a-12f7570b0444"
                                >
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse1b766c29-49c7-4270-8f7a-12f7570b0444"
                                        aria-expanded="false"
                                        aria-controls="collapse1b766c29-49c7-4270-8f7a-12f7570b0444"
                                    >
                                        <div class="">
                                            <span
                                                class="fs-6 me-3 badge text-uppercase bg-success _2rEdj"
                                            >
                                                post
                                            </span>
                                            <span class="fw-bold font-monospace me-3">
                                                {`/v1/user/${userData?._id ?? 'userId'}/withdrawal`}
                                            </span>
                                            <small>Create a withdrawal</small>
                                        </div>
                                    </button>
                                </h2>
                                <div
                                    id="collapse1b766c29-49c7-4270-8f7a-12f7570b0444"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="heading1b766c29-49c7-4270-8f7a-12f7570b0444"
                                    data-bs-parent="#accordion"
                                    style={{}}
                                >
                                    <div class="accordion-body">
                                        <div style={{ opacity: "1" }}>
                                            <div>
                                                <h6>Parameters</h6>
                                                <table
                                                    class="table table-bordered mt-3"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <td width="150">
                                                                <small>Name</small>
                                                            </td>
                                                            <td>
                                                                <small>
                                                                    Description
                                                                </small>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span class="fw-bold">
                                                                    userId
                                                                </span>
                                                                <span
                                                                    class="text-danger fw-bold"
                                                                >
                                                                    &nbsp;
                                                                    <span
                                                                        class="_1o829"
                                                                    >
                                                                        * required
                                                                    </span>
                                                                </span>
                                                                <br />
                                                                <small
                                                                    class="font-monospace"
                                                                    style={{
                                                                        letterSpacing: "0.5px",
                                                                        fontSize: "12px"
                                                                    }}

                                                                >
                                                                    string
                                                                </small>
                                                                <br />
                                                                <small
                                                                    class="fst-italic"
                                                                >
                                                                    (path)
                                                                </small>
                                                            </td>
                                                            <td>
                                                                <small>
                                                                    The unique
                                                                    identifier of a user
                                                                </small>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="mb-3">
                                                <h6>
                                                    Request Body
                                                    <span class="text-danger fw-bold">
                                                        &nbsp;
                                                        <span class="_1o829">
                                                            * required
                                                        </span>
                                                    </span>
                                                </h6>
                                                <p></p>
                                                <select class="form-select mt-3">
                                                    <option value="application/json">
                                                        application/json
                                                    </option>
                                                </select>
                                                <div class="card mt-2 mb-2">
                                                    <div class="card-body">
                                                        <div
                                                            class="card bg-dark text-light"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            <div class="card-body">
                                                                <div
                                                                    class="font-monospace"
                                                                >
                                                                    <span>
                                                                        {
                                                                            <div
                                                                                class="ms-2"
                                                                            >
                                                                                <div>
                                                                                    <span>
                                                                                        "paymentNumber":&nbsp;
                                                                                        <span>
                                                                                            <span
                                                                                                style={{ color: "rgb(0,218,145)" }}
                                                                                            >
                                                                                                "string"
                                                                                            </span>
                                                                                        </span>
                                                                                        ,
                                                                                    </span>
                                                                                </div>
                                                                                <div>
                                                                                    <span>
                                                                                        "amount":&nbsp;
                                                                                        <span>
                                                                                            <span
                                                                                                style={{ color: "rgb(0,218,145)" }}
                                                                                            >
                                                                                                "string"
                                                                                            </span>
                                                                                        </span>
                                                                                        ,
                                                                                    </span>
                                                                                </div>
                                                                                <div>
                                                                                    <span>
                                                                                        "message":&nbsp;
                                                                                        <span>
                                                                                            <span
                                                                                                style={{ color: "rgb(0,218,145)" }}

                                                                                            >
                                                                                                "string"
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <h6>Responses</h6>
                                                <table
                                                    class="table table-bordered mt-3"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <td width="150">
                                                                <small>Code</small>
                                                            </td>
                                                            <td>
                                                                <small>
                                                                    Description
                                                                </small>
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span class="fw-bold">
                                                                    200
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <small>
                                                                    Payment sent
                                                                    successfully
                                                                </small>
                                                                <div
                                                                    class="card bg-dark text-light"
                                                                    style={{ fontSize: "12px" }}
                                                                >
                                                                    <div
                                                                        class="card-body"
                                                                    >
                                                                        <div
                                                                            class="font-monospace"
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    <div
                                                                                        class="ms-2"
                                                                                    >
                                                                                        <div>
                                                                                            <span>
                                                                                                "message":&nbsp;
                                                                                                <span>
                                                                                                    <span
                                                                                                        style={{ color: "rgb(0.214.145)" }}

                                                                                                    >
                                                                                                        "string"
                                                                                                    </span>
                                                                                                </span>
                                                                                                ,
                                                                                            </span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>
                                                                                                "transactionId":&nbsp;
                                                                                                <span>
                                                                                                    <span
                                                                                                        style={{ color: "rgb(0,218,145)" }}

                                                                                                    >
                                                                                                        "string"
                                                                                                    </span>
                                                                                                </span>
                                                                                                ,
                                                                                            </span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>
                                                                                                "paymentNumber":&nbsp;
                                                                                                <span>
                                                                                                    <span
                                                                                                        style={{ color: "rgb(0,218,145)" }}
                                                                                                    >
                                                                                                        "string"
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mb-4 mt-4">
                        <h2>Schema</h2>
                        <div class="accordion" id="accordion">
                            <div class="accordion-item">
                                <h2
                                    class="accordion-header"
                                    id="b108045b-bd63-473b-8300-d31b73a49b17"
                                >
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseb108045b-bd63-473b-8300-d31b73a49b17"
                                        aria-expanded="true"
                                        aria-controls="collapseb108045b-bd63-473b-8300-d31b73a49b17"
                                    >
                                        <h6 class="mb-0">200 Response</h6>
                                    </button>
                                </h2>
                                <div
                                    id="collapseb108045b-bd63-473b-8300-d31b73a49b17"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="headingb108045b-bd63-473b-8300-d31b73a49b17"
                                    data-bs-parent="#accordion"
                                    style={{}}
                                >
                                    <div class="accordion-body">
                                        <div class="font-monospace d-block _1ppBF">
                                            {
                                                <><div class="ms-3">
                                                    <span class="fw-bold text-dark">
                                                        message
                                                    </span>
                                                    <span class="text-danger fw-bold">
                                                        &nbsp;
                                                        <span class="_1o829">*</span>
                                                    </span>
                                                    &nbsp;
                                                    <span class="text-primary">string</span>
                                                </div><div class="ms-3">
                                                        <span class="fw-bold text-dark">
                                                            transactionId
                                                        </span>
                                                        <span class="text-danger fw-bold">
                                                            &nbsp;
                                                            <span class="_1o829">*</span>
                                                        </span>
                                                        &nbsp;
                                                        <span class="text-primary">string</span>
                                                    </div><div class="ms-3">
                                                        <span class="fw-bold text-dark">
                                                            paymentNumber
                                                        </span>
                                                        <span class="text-danger fw-bold">
                                                            &nbsp;
                                                            <span class="_1o829">*</span>
                                                        </span>
                                                        &nbsp;
                                                        <span class="text-primary">string</span>
                                                    </div></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2
                                    class="accordion-header"
                                    id="16abe046-51a7-406d-b045-861dd8b1255e"
                                >
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse16abe046-51a7-406d-b045-861dd8b1255e"
                                        aria-expanded="true"
                                        aria-controls="collapse16abe046-51a7-406d-b045-861dd8b1255e"
                                    >
                                        <h6 class="mb-0">Request body</h6>
                                    </button>
                                </h2>
                                <div
                                    id="collapse16abe046-51a7-406d-b045-861dd8b1255e"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="heading16abe046-51a7-406d-b045-861dd8b1255e"
                                    data-bs-parent="#accordion"
                                    style={{}}
                                >
                                    <div class="accordion-body">
                                        <div class="font-monospace d-block _1ppBF">
                                            {
                                                <><div class="ms-3">
                                                    <span class="fw-bold text-dark">
                                                        paymentNumber
                                                    </span>
                                                    &nbsp;
                                                    <span class="text-primary">string</span>
                                                </div><div class="ms-3">
                                                        <span class="fw-bold text-dark">
                                                            amount
                                                        </span>
                                                        &nbsp;
                                                        <span class="text-primary">string</span>
                                                    </div><div class="ms-3">
                                                        <span class="fw-bold text-dark">
                                                            message
                                                        </span>
                                                        &nbsp;
                                                        <span class="text-primary">string</span>
                                                    </div></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4 mt-4">
                        <h2>Security Schema</h2>
                        <div class="accordion" id="accordion">
                            <div class="accordion-item">
                                <h2
                                    class="accordion-header"
                                    id="0584897a-944e-44ad-89f2-ec48090467be"
                                >
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse0584897a-944e-44ad-89f2-ec48090467be"
                                        aria-expanded="true"
                                        aria-controls="collapse0584897a-944e-44ad-89f2-ec48090467be"
                                    >
                                        <h6 class="mb-0">client_id (Custom Header)</h6>
                                    </button>
                                </h2>
                                <div
                                    id="collapse0584897a-944e-44ad-89f2-ec48090467be"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="heading0584897a-944e-44ad-89f2-ec48090467be"
                                    data-bs-parent="#accordion"
                                    style={{}}
                                >
                                    <div class="accordion-body">
                                        <div>
                                            <small class="d-block">In: header</small>
                                            <small class="d-block">
                                                <span className="me-2 fw-bold">Name:</span> client_id
                                            </small>
                                            <small class="d-block">
                                                <span className="me-2 fw-bold">Value:</span> {userData?.clientId ?? 'Loading ...'}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2
                                    class="accordion-header"
                                    id="6feb66af-0b98-402d-bb2f-96c2a6d48917"
                                >
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse6feb66af-0b98-402d-bb2f-96c2a6d48917"
                                        aria-expanded="true"
                                        aria-controls="collapse6feb66af-0b98-402d-bb2f-96c2a6d48917"
                                    >
                                        <h6 class="mb-0">client_secret (Custom Header)</h6>
                                    </button>
                                </h2>
                                <div
                                    id="collapse6feb66af-0b98-402d-bb2f-96c2a6d48917"
                                    class="accordion-collapse collapse show"
                                    aria-labelledby="heading6feb66af-0b98-402d-bb2f-96c2a6d48917"
                                    data-bs-parent="#accordion"
                                    style={{}}
                                >
                                    <div class="accordion-body">
                                        <div>
                                            <small class="d-block">In: header</small>
                                            <small class="d-block">
                                                <span className="me-2 fw-bold">Name:</span> client_secret
                                            </small>
                                            <small class="d-block">
                                                <span className="me-2 fw-bold">Value:</span> {userData?.clientSecret ?? 'Loading ...'}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default APIDoc;