import API from "../../backend";

export const getUserById = async (userId, token) => {
	return fetch(`${API}/user/${userId}`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const createLifafa = async (data, userId, token) => {
	return fetch(`${API}/user/${userId}/lifafa`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const getTotalLifafaPages = async (userId, token) => {
	return fetch(`${API}/user/${userId}/lifafas/totalPages`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const getLifafasByPage = async (userId, {page, pageSize}, token) => {
	return fetch(`${API}/user/${userId}/lifafa?pageSize=${pageSize}&pageNumber=${page}`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const claimLifafa = async (lifafaId, data) => {
	return fetch(`${API}/lifafa/${lifafaId}/claim`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const getLifafaById = async (lifafaId) => {
	return fetch(`${API}/lifafa/${lifafaId}`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json"
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const addUserBalance = async (userId, data, token) => {
	return fetch(`${API}/v1/user/${userId}/addBalance`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const getTotalTransactionPages = async (userId, token) => {
	return fetch(`${API}/v1/user/${userId}/transactions/totalPages`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const getTransactionsByPage = async (userId, {page, pageSize}, token) => {
	return fetch(`${API}/v1/user/${userId}/transactions-pg?pageSize=${pageSize}&pageNumber=${page}`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const getUserSecrets = async (userId, token) => {
	return fetch(`${API}/user/${userId}/secrets`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const makeWithdraw = async (userId, data, token) => {
	return fetch(`${API}/v1/user/${userId}/withdraw`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const getUserSettings = async (userId, token) => {
	return fetch(`${API}/user/${userId}/settings`, {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		}
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}

export const updateUserSettings = async (userId, data, token) => {
	return fetch(`${API}/user/${userId}/settings`, {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			"Authorization": `Bearer ${token}`
		},
		body: JSON.stringify(data)
	})
		.then(response => response.json())
		.catch(err => console.log(err))
}