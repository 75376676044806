import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ShowLoading from "../components/ShowLoading";
import ShowError from "../components/ShowError";
import ShowSuccess from "../components/ShowSuccess";
import { getUserDetailsById, updateUserDetailsById } from "./helpers/adminapis";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

function ManageUser(_props) {

    const admin = useSelector(state => state.user);
    const { adminId, userId } = useParams();
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        mobileNumber: "",
        balance: 0,
        withdrawalsToday: 0,
        moneyWithdrawnToday: 0,
        totalWithdrawn: 0,
        clientId: "",
        clientSecret: "",
        commission: "",
        status: "",
        password: "",
        allowListHosts: [],
        allowListIPs: []
    });
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    const { name, email, mobileNumber, balance, withdrawalsToday, moneyWithdrawnToday, totalWithdrawn, clientId, clientSecret, commission, status, password, allowListHosts, allowListIPs } = userData;

    const handleChange = name => (event) => {
        event.preventDefault();
        setUserData({ ...userData, [name]: event.target.value });
        setError(false);
    }

    const handleClick = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = {
            name,
            email,
            mobileNumber,
            balance,
            commission,
            status,
            ...(!isEmpty(password) && { password: password }),
            ...(allowListHosts !== "" ? { allowListHosts: allowListHosts.split(",").map(x => x.trim()) } : { allowListHosts: [] }),
            ...(allowListIPs !== "" ? { allowListIPs: allowListIPs.split(",").map(x => x.trim()) } : { allowListIPs: [] })
        }
        updateUserDetailsById(adminId, userId, data, admin.token)
            .then(data => {
                if (data?.error) {
                    setError(data.error)
                } else {
                    setSuccess(data.message)
                }
                setIsLoading(false)
            })
            .catch(err => console.log(err) && setError(err.message) && setIsLoading(false))
    }

    const setUserDetails = () => {
        getUserDetailsById(adminId, userId, admin.token)
            .then(user => {
                if (user?.error) {
                    setError(user?.error);
                } else {
                    setUserData({
                        ...user,
                        allowListHosts: user.allowListHosts?.join(', ') ?? "",
                        allowListIPs: user.allowListIPs?.join(', ') ?? ""
                    });
                }
                setIsLoading(false)
            })
            .catch(err => console.log(err) && setError(err.message) && setIsLoading(false))
    }

    useEffect(() => {
        setUserDetails();
    }, [])

    return (
        <div>
            <Header />
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 mb-5">
                        <ShowError error={error} />
                        <ShowLoading isLoading={isLoading} />
                        <ShowSuccess success={success} />
                        <div className="card px-5 py-5 my-5">
                            <div className="form-data">

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Full name</span>
                                    <input type="text" onChange={handleChange("name")} value={name} className="form-control mt-1" />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Email address</span>
                                    <input type="email" onChange={handleChange("email")} value={email} className="form-control mt-1" />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Mobile number</span>
                                    <input type="tel" onChange={handleChange("mobileNumber")} value={mobileNumber} className="form-control mt-1" />
                                </div>

                                <div className="forms-inputs  mb-4">
                                    <span className="dark-text fw-bold">Balance</span>
                                    <input type="tel" onChange={handleChange("balance")} value={balance} className="form-control mt-1" />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Withdrawals Today</span>
                                    <input type="tel" onChange={handleChange("withdrawalsToday")} value={withdrawalsToday} className="form-control mt-1" disabled readonly />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Money Withdrawn Today</span>
                                    <input type="tel" onChange={handleChange("moneyWithdrawnToday")} value={moneyWithdrawnToday} className="form-control mt-1" disabled readonly />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Total Withdrawn</span>
                                    <input type="tel" onChange={handleChange("totalWithdrawn")} value={totalWithdrawn} className="form-control mt-1" disabled readonly />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Client Id</span>
                                    <input type="text" onChange={handleChange("clientId")} value={clientId} className="form-control mt-1" disabled readonly />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Client Secret</span>
                                    <input type="text" onChange={handleChange("clientSecret")} value={clientSecret} className="form-control mt-1" disabled readonly />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Commission</span>
                                    <input type="tel" onChange={handleChange("commission")} value={commission} className="form-control mt-1" />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Status</span>
                                    <select class="form-select" onChange={handleChange("status")} value={status}>
                                        <option value="active">Active</option>
                                        <option value="pause">Pause</option>
                                        <option value="banned">Banned</option>
                                    </select>
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">
                                        Allowed Domains for Withdrawal API  (Comma separated)
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={allowListHosts}
                                        onChange={handleChange("allowListHosts")}
                                    />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">
                                        Allowed IPs for Withdrawal API  (Comma separated)
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control mt-1"
                                        value={allowListIPs}
                                        onChange={handleChange("allowListIPs")}
                                    />
                                </div>

                                <div className="forms-inputs mb-4">
                                    <span className="dark-text fw-bold">Password</span>
                                    <input type="text" onChange={handleChange("password")} value={password} className="form-control mt-1" />
                                </div>

                                <div className="mb-3">
                                    <button className="btn btn-dark w-100 mt-3 fw-bold" onClick={handleClick}>Update User</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer small={false} />
        </div>
    );
}

export default ManageUser;