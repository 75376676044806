import { createSlice } from "@reduxjs/toolkit";
import { isAuthenticated } from "../../auth/index";

const data = isAuthenticated();

const initialState = {
	_id: data?._id ?? "",
	role: data ? data.role === 0 ? 0 : data.role : -1,
	name: data?.name ?? "",
	email: data?.email ?? "",
	mobileNumber: data?.mobileNumber ?? "",
	avatar: data?.avatar ?? "",
	status: data?.status ?? "",
	token: data?.token ?? "",
	balance: null,
	commission: null,
	withdrawalsToday: null,
	moneyWithdrawnToday: null,
	totalWithdrawn: null,
	totalLifafas: null,
	activeLifafas: null
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserData: (state, action) => {
			state._id = action.payload._id;
			state.role = action.payload.role;
			state.name = action.payload.name;
			state.email = action.payload.email;
			state.mobileNumber = action.payload.mobileNumber;
			state.avatar = action.payload.avatar;
			state.token = action.payload.token;
			state.status = action.payload.status;
		},
		resetUserData: (state) => {
			state._id = "";
			state.role = -1;
			state.name = "";
			state.email = "";
			state.mobileNumber = "";
			state.avatar = "";
			state.token = "";
			state.status = "";
			state.balance = null;
			state.commission = null;
			state.withdrawalsToday = null;
			state.moneyWithdrawnToday = null;
			state.totalWithdrawn = null;
		},
		updateUserData: (state, action) => {
			state.name = action.payload.name;
			state.email = action.payload.email;
			state.mobileNumber = action.payload.mobileNumber;
		},
		setAdditionalUserData: (state, action) => {
			state.balance = action.payload.balance;
			state.commission = action.payload.commission;
			state.withdrawalsToday = action.payload.withdrawalsToday;
			state.moneyWithdrawnToday = action.payload.moneyWithdrawnToday;
			state.totalWithdrawn = action.payload.totalWithdrawn;
			state.totalLifafas = action.payload.totalLifafas;
			state.activeLifafas = action.payload.activeLifafas;
		}
	}
});

export const { setUserData, resetUserData, updateUserData, setAdditionalUserData } = userSlice.actions;

export default userSlice.reducer;