import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from "../helper/userapi";
import { setAdditionalUserData } from "../../state/reducers/userReducers";
import ShowLoading from "../../components/ShowLoading";
import ShowError from "../../components/ShowError";

function UserProfile(_props) {

	const user = useSelector((state) => state.user);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();

	const getUserAdditionalDetails = () => {
		getUserById(user._id, user.token)
			.then((res) => {
				if (res?.error) {
					setIsLoading(false)
					return setError(res.error)
				}

				if (!res?.error) {
					dispatch(setAdditionalUserData({
						balance: res.balance,
						commission: res.commission,
						withdrawalsToday: res.withdrawalsToday,
						moneyWithdrawnToday: res.moneyWithdrawnToday,
						totalWithdrawn: res.totalWithdrawn,
						totalLifafas: res.totalLifafas,
						activeLifafas: res.activeLifafas
					}))
				}

				setIsLoading(false)
			})
			.catch((error) => console.log(error) && setError(error) && setIsLoading(false))
	}

	useEffect(() => {
		getUserAdditionalDetails();
	}, []);

	return (
		<div class="d-flex justify-content-center">

			<div class="col-md-10">
				<div class="row ">
					<ShowLoading isLoading={isLoading} />
					<ShowError error={error} />
					<div class="col-xl-6 col-lg-6">
						<div class="card-ud l-bg-purple shadow">
							<div class="card-statistic-3 p-4">
								<div class="card-icon card-icon-large"><i class="fas fa-wallet"></i></div>
								<div class="mb-4">
									<h5 class="card-title mb-0">Balance</h5>
								</div>
								<div class="row align-items-center mb-2 d-flex">
									<div class="col-8">
										<h2 class="d-flex align-items-center mb-0">
											{(user?.balance ?? 0).toLocaleString('en-IN', {
												style: 'currency',
												currency: 'INR'
											})}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-6">
						<div class="card-ud l-bg-blue-dark shadow">
							<div class="card-statistic-3 p-4">
								<div class="card-icon card-icon-large"><i class="fas fa-users"></i></div>
								<div class="mb-4">
									<h5 class="card-title mb-0">Withdrawals Today</h5>
								</div>
								<div class="row align-items-center mb-2 d-flex">
									<div class="col-8">
										<h2 class="d-flex align-items-center mb-0">
											{user?.withdrawalsToday ?? 0}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-6">
						<div class="card-ud l-bg-green-dark shadow">
							<div class="card-statistic-3 p-4">
								<div class="card-icon card-icon-large"><i class="fas fa-coins"></i></div>
								<div class="mb-4">
									<h5 class="card-title mb-0">Money Withdrawn Today</h5>
								</div>
								<div class="row align-items-center mb-2 d-flex">
									<div class="col-8">
										<h2 class="d-flex align-items-center mb-0">
											{(user?.moneyWithdrawnToday ?? 0).toLocaleString('en-IN', {
												style: 'currency',
												currency: 'INR'
											})}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-6">
						<div class="card-ud l-bg-orange-dark shadow">
							<div class="card-statistic-3 p-4">
								<div class="card-icon card-icon-large"><i class="fas fa-dollar-sign"></i></div>
								<div class="mb-4">
									<h5 class="card-title mb-0">Total Withdrawn Till Now</h5>
								</div>
								<div class="row align-items-center mb-2 d-flex">
									<div class="col-8">
										<h2 class="d-flex align-items-center mb-0">
											{(user?.totalWithdrawn ?? 0).toLocaleString('en-IN', {
												style: 'currency',
												currency: 'INR'
											})}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-6">
						<div class="card-ud l-bg-pink shadow">
							<div class="card-statistic-3 p-4">
								<div class="card-icon card-icon-large"><i class="fas fa-envelope"></i></div>
								<div class="mb-4">
									<h5 class="card-title mb-0">Active lifafas</h5>
								</div>
								<div class="row align-items-center mb-2 d-flex">
									<div class="col-8">
										<h2 class="d-flex align-items-center mb-0">
											{user?.activeLifafas ?? 0}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-6">
						<div class="card-ud l-bg-cherry shadow">
							<div class="card-statistic-3 p-4">
								<div class="card-icon card-icon-large"><i class="fas fa-envelope-circle-check"></i></div>
								<div class="mb-4">
									<h5 class="card-title mb-0">Total Lifafas</h5>
								</div>
								<div class="row align-items-center mb-2 d-flex">
									<div class="col-8">
										<h2 class="d-flex align-items-center mb-0">
											{user?.totalLifafas ?? 0}
										</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserProfile;
