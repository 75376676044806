import React from 'react';

function UserNav(props) {

	return (<>
		<ul className="list-group mb-3 shadow">
			<li className={props.activeTab === "profile" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("profile")}><i class="fas fa-id-card me-2"></i> My profile</li>
			<li className={props.activeTab === "edit" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("edit")}><i class="fas fa-user-pen me-2"></i> Edit profile</li>
			<li className={props.activeTab === "addBalance" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("addBalance")}><i class="fas fa-coins me-2"></i> Add Balance</li>
			<li className={props.activeTab === "createLifafa" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("createLifafa")}><i class="fas fa-envelope-open-text me-2"></i> Create Lifafa</li>
			<li className={props.activeTab === "listLifafa" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("listLifafa")}><i class="fas fa-envelope me-2"></i> All Lifafas</li>
			<li className={props.activeTab === "transactionLogs" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("transactionLogs")}><i class="fas fa-money-bill-transfer me-2"></i> Transaction Logs</li>
			<li className={props.activeTab === "apiDocs" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("apiDocs")}><i class="fas fa-book me-2"></i> API Documentation</li>
			<li className={props.activeTab === "withdraw" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("withdraw")}><i class="fas fa-money-bill-trend-up me-2"></i> Withdraw</li>
			{/* <li className={props.activeTab === "settings" ? "list-group-item fw-bold text-center active-tab" : "list-group-item fw-bold text-center"} onClick={() => props.setActiveTab("settings")}><i class="fas fa-gear me-2"></i> Settings</li> */}
		</ul>
	</>);
}

export default UserNav;